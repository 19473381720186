import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

//Importing main layout
import { Main } from "../layouts/main"

// Importing components
import AboutBrief from "../components/about-brief"
import CategorySelector from "../components/category-selector"
import Slider from "../components/slider"

// Importing styles
import "./styles/index.scss"

// Importing Bootstrap components
import { Container, Row } from "react-bootstrap"

// Importing icons
import { FaShippingFast } from "react-icons/fa"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }
  }
`
export default function IndexPage({ data }) {
  return (
    <Main>
      <div className="homepage">
        <section className="intro">
          <div className="overlay" />
          <Container>
            <Row className="content fade-in-slow">
              <StaticImage
                className="image"
                src="../assets/images/intro-logo.png"
                alt="The Elevated Life logo."
                placeholder="tracedSVG"
              />
              <h1 className="yell">
                <FaShippingFast />
                We deliver locally!
              </h1>
              <p className="talk">Free shipping on orders over $80</p>
            </Row>
          </Container>
        </section>
        <Slider />
        <CategorySelector />
        <AboutBrief />
      </div>
    </Main>
  )
}
