import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// Importing styles
import "./styles/slider.scss"

// Bootstrap imports
import { Button, Carousel, Container } from "react-bootstrap"

function Slider() {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpSlide {
        nodes {
          slideFields {
            button {
              buttonLink
              buttonText
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            paragraph
            title
          }
        }
      }
    }
  `)

  // Wordpress data
  const { allWpSlide } = data

  return (
    <section className="slider">
      <Carousel touch="true">
        {allWpSlide.nodes.map((slide) => (
          <Carousel.Item>
            <Carousel.Caption>
              <div className="text fade-in-slow">
                <GatsbyImage
                  className="thumbnail"
                  image={
                    slide.slideFields.image.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={slide.slideFields.image.altText}
                />
                <div className="text-content">
                  <h2 className="shout title">{slide.slideFields.title}</h2>
                  <p className="talk">{slide.slideFields.paragraph}</p>
                  <Link to={slide.slideFields.button.buttonLink}>
                    <Button variant="accent">
                      {slide.slideFields.button.buttonText}
                    </Button>
                  </Link>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  )
}

export default Slider
