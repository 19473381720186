import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "@sindresorhus/slugify"

// Importing styles
import "./styles/category-selector.scss"

// Bootstrap imports
import { Col, Container, Row } from "react-bootstrap"

function CategorySelector() {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allShopifyCollection(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            image {
              altText
              gatsbyImageData
            }
            handle
            title
          }
        }
      }
    }
  `)

  // Wordpress data
  const { allShopifyCollection } = data

  return (
    <section className="category-selector">
      <div className="title">
        <span className="whisper">&mdash; Browse our proudcts &mdash;</span>
        <h1 className="shout">Shop By Collection</h1>
      </div>
      <div className="category-list-container">
        <Container>
          <Row className="category-list fade-in-fast">
            {allShopifyCollection.edges.map((collection) => {
              return (
                <Col
                  className="collection"
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={collection.node.id}
                >
                  <Link to={`collections/${collection.node.handle}`}>
                    <div className="collection-container">
                      <GatsbyImage
                        className="image"
                        image={collection.node.image.gatsbyImageData}
                        alt={collection.node.image.altText}
                      />
                      <h3 className="talk">{collection.node.title}</h3>
                    </div>
                  </Link>
                </Col>
              )
            })}
            <div className="browse-all">
              <Link className="talk" to="/products">
                Browse all products &rarr;
              </Link>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default CategorySelector
