import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

// Importing styles
import "./styles/about-brief.scss"

// Bootstrap imports
import { Container } from "react-bootstrap"

function AboutBrief() {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Homepage" }) {
        homepageFields {
          aboutParagraph
        }
      }
    }
  `)

  // Wordpress data
  const { wpPage } = data
  return (
    <section className="about-brief">
      <Container>
        <div className="title">
          <span className="whisper">
            &mdash; What makes us different? &mdash;
          </span>
          <h1 className="shout">A Little About Us</h1>
        </div>
        <div className="story">
          <div
            className="talk"
            dangerouslySetInnerHTML={{
              __html: wpPage.homepageFields.aboutParagraph,
            }}
          />
        </div>
      </Container>
    </section>
  )
}

export default AboutBrief
